export const securityListData = [
  {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
    <path d="M21.9995 76.0003C17.0844 75.9944 12.3436 74.1788 8.6819 70.9C5.02023 67.6213 2.69411 63.109 2.14753 58.2244C1.60095 53.3398 2.87218 48.425 5.71859 44.418C8.565 40.411 12.7872 37.5924 17.5795 36.5003C17.7073 36.4735 17.8285 36.4216 17.936 36.3476C18.0436 36.2736 18.1354 36.1789 18.2061 36.0691C18.2768 35.9594 18.325 35.8366 18.3479 35.7081C18.3708 35.5795 18.3679 35.4477 18.3395 35.3203C18.0102 33.7089 17.9225 32.0575 18.0795 30.4203C18.3209 27.937 19.1406 25.5447 20.4729 23.4352C21.8053 21.3257 23.6131 19.5576 25.7517 18.2725C27.8903 16.9874 30.3002 16.221 32.7883 16.0348C35.2763 15.8486 37.7734 16.2478 40.0795 17.2003C40.3091 17.2934 40.5652 17.2978 40.798 17.2129C41.0308 17.1279 41.2238 16.9595 41.3395 16.7403C44.1823 11.4638 48.6409 7.23689 54.0615 4.67937C59.482 2.12185 65.5794 1.36825 71.4595 2.52907C77.3396 3.68988 82.6932 6.70404 86.7352 11.1296C90.7772 15.5552 93.295 21.1593 93.9195 27.1203C93.9435 27.361 94.054 27.5849 94.2306 27.7504C94.4071 27.9159 94.6376 28.0118 94.8795 28.0203C100.809 28.2516 106.442 30.6714 110.692 34.8125C114.942 38.9535 117.508 44.5222 117.893 50.4436C118.278 56.365 116.456 62.2192 112.779 66.8761C109.101 71.533 103.829 74.6625 97.9795 75.6603L21.9995 76.0003Z" fill="#60DCDD"/>
    <path d="M98 64V52C98 50.4087 97.3679 48.8826 96.2426 47.7574C95.1174 46.6321 93.5913 46 92 46H28C26.4087 46 24.8826 46.6321 23.7574 47.7574C22.6321 48.8826 22 50.4087 22 52V64C22 65.5913 22.6321 67.1174 23.7574 68.2426C24.8826 69.3679 26.4087 70 28 70C26.4087 70 24.8826 70.6321 23.7574 71.7574C22.6321 72.8826 22 74.4087 22 76V88C22 89.5913 22.6321 91.1174 23.7574 92.2426C24.8826 93.3679 26.4087 94 28 94C26.4087 94 24.8826 94.6321 23.7574 95.7574C22.6321 96.8826 22 98.4087 22 100V112C22 113.591 22.6321 115.117 23.7574 116.243C24.8826 117.368 26.4087 118 28 118H92C93.5913 118 95.1174 117.368 96.2426 116.243C97.3679 115.117 98 113.591 98 112V100C98 98.4087 97.3679 96.8826 96.2426 95.7574C95.1174 94.6321 93.5913 94 92 94C93.5913 94 95.1174 93.3679 96.2426 92.2426C97.3679 91.1174 98 89.5913 98 88V76C98 74.4087 97.3679 72.8826 96.2426 71.7574C95.1174 70.6321 93.5913 70 92 70C93.5913 70 95.1174 69.3679 96.2426 68.2426C97.3679 67.1174 98 65.5913 98 64Z" fill="#5498C8"/>
    <path d="M92 70H28C24.6863 70 22 72.6863 22 76V88C22 91.3137 24.6863 94 28 94H92C95.3137 94 98 91.3137 98 88V76C98 72.6863 95.3137 70 92 70Z" fill="#4184A9"/>
    <path d="M34 86C36.2091 86 38 84.2091 38 82C38 79.7909 36.2091 78 34 78C31.7909 78 30 79.7909 30 82C30 84.2091 31.7909 86 34 86Z" fill="#FDE05E"/>
    <path d="M50 86C52.2091 86 54 84.2091 54 82C54 79.7909 52.2091 78 50 78C47.7909 78 46 79.7909 46 82C46 84.2091 47.7909 86 50 86Z" fill="#60DCDD"/>
    <path d="M34 62C36.2091 62 38 60.2091 38 58C38 55.7909 36.2091 54 34 54C31.7909 54 30 55.7909 30 58C30 60.2091 31.7909 62 34 62Z" fill="#FDE05E"/>
    <path d="M50 62C52.2091 62 54 60.2091 54 58C54 55.7909 52.2091 54 50 54C47.7909 54 46 55.7909 46 58C46 60.2091 47.7909 62 50 62Z" fill="#60DCDD"/>
    <path d="M34 110C36.2091 110 38 108.209 38 106C38 103.791 36.2091 102 34 102C31.7909 102 30 103.791 30 106C30 108.209 31.7909 110 34 110Z" fill="#FDE05E"/>
    <path d="M50 110C52.2091 110 54 108.209 54 106C54 103.791 52.2091 102 50 102C47.7909 102 46 103.791 46 106C46 108.209 47.7909 110 50 110Z" fill="#60DCDD"/>
    <path d="M49.9996 37.9998C49.4692 37.9997 48.9606 37.7889 48.5856 37.4138L42.5856 31.4138C42.2106 31.0388 42 30.5302 42 29.9998C42 29.4695 42.2106 28.9609 42.5856 28.5858L48.5856 22.5858C48.9628 22.2215 49.468 22.0199 49.9924 22.0245C50.5168 22.029 51.0184 22.2394 51.3892 22.6102C51.76 22.981 51.9704 23.4826 51.9749 24.007C51.9795 24.5314 51.7779 25.0366 51.4136 25.4138L46.8276 29.9998L51.4136 34.5858C51.6932 34.8655 51.8836 35.2219 51.9607 35.6098C52.0379 35.9977 51.9983 36.3997 51.8469 36.7651C51.6956 37.1305 51.4393 37.4429 51.1105 37.6626C50.7817 37.8824 50.3951 37.9998 49.9996 37.9998Z" fill="white"/>
    <path d="M69.9996 37.9998C69.6041 37.9998 69.2175 37.8824 68.8887 37.6626C68.5598 37.4429 68.3036 37.1305 68.1522 36.7651C68.0009 36.3997 67.9613 35.9977 68.0384 35.6098C68.1155 35.2219 68.306 34.8655 68.5856 34.5858L73.1716 29.9998L68.5856 25.4138C68.2213 25.0366 68.0197 24.5314 68.0242 24.007C68.0288 23.4826 68.2391 22.981 68.6099 22.6102C68.9807 22.2394 69.4824 22.029 70.0068 22.0245C70.5312 22.0199 71.0364 22.2215 71.4136 22.5858L77.4136 28.5858C77.7885 28.9609 77.9991 29.4695 77.9991 29.9998C77.9991 30.5302 77.7885 31.0388 77.4136 31.4138L71.4136 37.4138C71.0386 37.7889 70.53 37.9997 69.9996 37.9998Z" fill="white"/>
    <path d="M58.0004 38.0004C57.6837 38.0002 57.3716 37.9247 57.0897 37.7802C56.8078 37.6358 56.5643 37.4264 56.3792 37.1694C56.194 36.9124 56.0726 36.6151 56.0248 36.302C55.9771 35.9889 56.0043 35.6689 56.1044 35.3684L60.1044 23.3684C60.2721 22.8645 60.633 22.4478 61.1078 22.21C61.5827 21.9722 62.1325 21.9328 62.6364 22.1004C63.1403 22.268 63.557 22.6289 63.7948 23.1038C64.0326 23.5786 64.0721 24.1285 63.9044 24.6324L59.9044 36.6324C59.7714 37.0318 59.5158 37.379 59.1739 37.6246C58.832 37.8703 58.4214 38.0018 58.0004 38.0004Z" fill="white"/>
    <path d="M70 80H68C67.4696 80 66.9609 79.7893 66.5858 79.4142C66.2107 79.0391 66 78.5304 66 78C66 77.4696 66.2107 76.9609 66.5858 76.5858C66.9609 76.2107 67.4696 76 68 76H70C70.5304 76 71.0391 76.2107 71.4142 76.5858C71.7893 76.9609 72 77.4696 72 78C72 78.5304 71.7893 79.0391 71.4142 79.4142C71.0391 79.7893 70.5304 80 70 80Z" fill="#255A6B"/>
    <path d="M70 88H68C67.4696 88 66.9609 87.7893 66.5858 87.4142C66.2107 87.0391 66 86.5304 66 86C66 85.4696 66.2107 84.9609 66.5858 84.5858C66.9609 84.2107 67.4696 84 68 84H70C70.5304 84 71.0391 84.2107 71.4142 84.5858C71.7893 84.9609 72 85.4696 72 86C72 86.5304 71.7893 87.0391 71.4142 87.4142C71.0391 87.7893 70.5304 88 70 88Z" fill="#255A6B"/>
    <path d="M80 80H78C77.4696 80 76.9609 79.7893 76.5858 79.4142C76.2107 79.0391 76 78.5304 76 78C76 77.4696 76.2107 76.9609 76.5858 76.5858C76.9609 76.2107 77.4696 76 78 76H80C80.5304 76 81.0391 76.2107 81.4142 76.5858C81.7893 76.9609 82 77.4696 82 78C82 78.5304 81.7893 79.0391 81.4142 79.4142C81.0391 79.7893 80.5304 80 80 80Z" fill="#255A6B"/>
    <path d="M80 88H78C77.4696 88 76.9609 87.7893 76.5858 87.4142C76.2107 87.0391 76 86.5304 76 86C76 85.4696 76.2107 84.9609 76.5858 84.5858C76.9609 84.2107 77.4696 84 78 84H80C80.5304 84 81.0391 84.2107 81.4142 84.5858C81.7893 84.9609 82 85.4696 82 86C82 86.5304 81.7893 87.0391 81.4142 87.4142C81.0391 87.7893 80.5304 88 80 88Z" fill="#255A6B"/>
    <path d="M90 80H88C87.4696 80 86.9609 79.7893 86.5858 79.4142C86.2107 79.0391 86 78.5304 86 78C86 77.4696 86.2107 76.9609 86.5858 76.5858C86.9609 76.2107 87.4696 76 88 76H90C90.5304 76 91.0391 76.2107 91.4142 76.5858C91.7893 76.9609 92 77.4696 92 78C92 78.5304 91.7893 79.0391 91.4142 79.4142C91.0391 79.7893 90.5304 80 90 80Z" fill="#255A6B"/>
    <path d="M90 88H88C87.4696 88 86.9609 87.7893 86.5858 87.4142C86.2107 87.0391 86 86.5304 86 86C86 85.4696 86.2107 84.9609 86.5858 84.5858C86.9609 84.2107 87.4696 84 88 84H90C90.5304 84 91.0391 84.2107 91.4142 84.5858C91.7893 84.9609 92 85.4696 92 86C92 86.5304 91.7893 87.0391 91.4142 87.4142C91.0391 87.7893 90.5304 88 90 88Z" fill="#255A6B"/>
    <path d="M70 104H68C67.4696 104 66.9609 103.789 66.5858 103.414C66.2107 103.039 66 102.53 66 102C66 101.47 66.2107 100.961 66.5858 100.586C66.9609 100.211 67.4696 100 68 100H70C70.5304 100 71.0391 100.211 71.4142 100.586C71.7893 100.961 72 101.47 72 102C72 102.53 71.7893 103.039 71.4142 103.414C71.0391 103.789 70.5304 104 70 104Z" fill="#35718A"/>
    <path d="M70 112H68C67.4696 112 66.9609 111.789 66.5858 111.414C66.2107 111.039 66 110.53 66 110C66 109.47 66.2107 108.961 66.5858 108.586C66.9609 108.211 67.4696 108 68 108H70C70.5304 108 71.0391 108.211 71.4142 108.586C71.7893 108.961 72 109.47 72 110C72 110.53 71.7893 111.039 71.4142 111.414C71.0391 111.789 70.5304 112 70 112Z" fill="#35718A"/>
    <path d="M80 104H78C77.4696 104 76.9609 103.789 76.5858 103.414C76.2107 103.039 76 102.53 76 102C76 101.47 76.2107 100.961 76.5858 100.586C76.9609 100.211 77.4696 100 78 100H80C80.5304 100 81.0391 100.211 81.4142 100.586C81.7893 100.961 82 101.47 82 102C82 102.53 81.7893 103.039 81.4142 103.414C81.0391 103.789 80.5304 104 80 104Z" fill="#35718A"/>
    <path d="M80 112H78C77.4696 112 76.9609 111.789 76.5858 111.414C76.2107 111.039 76 110.53 76 110C76 109.47 76.2107 108.961 76.5858 108.586C76.9609 108.211 77.4696 108 78 108H80C80.5304 108 81.0391 108.211 81.4142 108.586C81.7893 108.961 82 109.47 82 110C82 110.53 81.7893 111.039 81.4142 111.414C81.0391 111.789 80.5304 112 80 112Z" fill="#35718A"/>
    <path d="M90 104H88C87.4696 104 86.9609 103.789 86.5858 103.414C86.2107 103.039 86 102.53 86 102C86 101.47 86.2107 100.961 86.5858 100.586C86.9609 100.211 87.4696 100 88 100H90C90.5304 100 91.0391 100.211 91.4142 100.586C91.7893 100.961 92 101.47 92 102C92 102.53 91.7893 103.039 91.4142 103.414C91.0391 103.789 90.5304 104 90 104Z" fill="#35718A"/>
    <path d="M90 112H88C87.4696 112 86.9609 111.789 86.5858 111.414C86.2107 111.039 86 110.53 86 110C86 109.47 86.2107 108.961 86.5858 108.586C86.9609 108.211 87.4696 108 88 108H90C90.5304 108 91.0391 108.211 91.4142 108.586C91.7893 108.961 92 109.47 92 110C92 110.53 91.7893 111.039 91.4142 111.414C91.0391 111.789 90.5304 112 90 112Z" fill="#35718A"/>
    <path d="M70 56H68C67.4696 56 66.9609 55.7893 66.5858 55.4142C66.2107 55.0391 66 54.5304 66 54C66 53.4696 66.2107 52.9609 66.5858 52.5858C66.9609 52.2107 67.4696 52 68 52H70C70.5304 52 71.0391 52.2107 71.4142 52.5858C71.7893 52.9609 72 53.4696 72 54C72 54.5304 71.7893 55.0391 71.4142 55.4142C71.0391 55.7893 70.5304 56 70 56Z" fill="#35718A"/>
    <path d="M70 64H68C67.4696 64 66.9609 63.7893 66.5858 63.4142C66.2107 63.0391 66 62.5304 66 62C66 61.4696 66.2107 60.9609 66.5858 60.5858C66.9609 60.2107 67.4696 60 68 60H70C70.5304 60 71.0391 60.2107 71.4142 60.5858C71.7893 60.9609 72 61.4696 72 62C72 62.5304 71.7893 63.0391 71.4142 63.4142C71.0391 63.7893 70.5304 64 70 64Z" fill="#35718A"/>
    <path d="M80 56H78C77.4696 56 76.9609 55.7893 76.5858 55.4142C76.2107 55.0391 76 54.5304 76 54C76 53.4696 76.2107 52.9609 76.5858 52.5858C76.9609 52.2107 77.4696 52 78 52H80C80.5304 52 81.0391 52.2107 81.4142 52.5858C81.7893 52.9609 82 53.4696 82 54C82 54.5304 81.7893 55.0391 81.4142 55.4142C81.0391 55.7893 80.5304 56 80 56Z" fill="#35718A"/>
    <path d="M80 64H78C77.4696 64 76.9609 63.7893 76.5858 63.4142C76.2107 63.0391 76 62.5304 76 62C76 61.4696 76.2107 60.9609 76.5858 60.5858C76.9609 60.2107 77.4696 60 78 60H80C80.5304 60 81.0391 60.2107 81.4142 60.5858C81.7893 60.9609 82 61.4696 82 62C82 62.5304 81.7893 63.0391 81.4142 63.4142C81.0391 63.7893 80.5304 64 80 64Z" fill="#35718A"/>
    <path d="M90 56H88C87.4696 56 86.9609 55.7893 86.5858 55.4142C86.2107 55.0391 86 54.5304 86 54C86 53.4696 86.2107 52.9609 86.5858 52.5858C86.9609 52.2107 87.4696 52 88 52H90C90.5304 52 91.0391 52.2107 91.4142 52.5858C91.7893 52.9609 92 53.4696 92 54C92 54.5304 91.7893 55.0391 91.4142 55.4142C91.0391 55.7893 90.5304 56 90 56Z" fill="#35718A"/>
    <path d="M90 64H88C87.4696 64 86.9609 63.7893 86.5858 63.4142C86.2107 63.0391 86 62.5304 86 62C86 61.4696 86.2107 60.9609 86.5858 60.5858C86.9609 60.2107 87.4696 60 88 60H90C90.5304 60 91.0391 60.2107 91.4142 60.5858C91.7893 60.9609 92 61.4696 92 62C92 62.5304 91.7893 63.0391 91.4142 63.4142C91.0391 63.7893 90.5304 64 90 64Z" fill="#35718A"/>
  </svg>`,
    title: "Flexible Deployment Options",
    description: `Our system offers deployment on both company cloud and on-premise servers, providing the flexibility to choose the solution that best suits your organization's needs.
    <br><br> Whether you prefer the scalability of the cloud or the control of on-premise infrastructure, we ensure reliable and secure deployment tailored to your requirements`,
  },
  {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
    <path d="M77.3125 101.475H102.422C104.364 101.475 105.938 99.9005 105.938 97.959C105.938 96.0174 104.364 94.4434 102.422 94.4434H77.3125C75.3709 94.4434 73.7969 96.0174 73.7969 97.959C73.7969 99.9005 75.3709 101.475 77.3125 101.475Z" fill="#CFDBFD"/>
    <path d="M83.9219 32.0349C85.8634 32.0349 87.4375 30.4609 87.4375 28.5193V17.4629H102.422C104.363 17.4629 105.937 15.8888 105.937 13.9473C105.937 12.0057 104.363 10.4316 102.422 10.4316H83.9219C81.9803 10.4316 80.4062 12.0057 80.4062 13.9473V28.5193C80.4062 30.4609 81.9803 32.0349 83.9219 32.0349Z" fill="#CFDBFD"/>
    <path d="M98.873 59.2598H116.484C118.426 59.2598 120 57.6857 120 55.7441C120 53.8026 118.426 52.2285 116.484 52.2285H98.873C96.9315 52.2285 95.3574 53.8026 95.3574 55.7441C95.3574 57.6857 96.9315 59.2598 98.873 59.2598Z" fill="#CFDBFD"/>
    <path d="M93.6973 75.9121H108.489C110.43 75.9121 112.005 74.338 112.005 72.3965C112.005 70.4549 110.43 68.8809 108.489 68.8809H93.6973C91.7557 68.8809 90.1816 70.4549 90.1816 72.3965C90.1816 74.338 91.7557 75.9121 93.6973 75.9121Z" fill="#CFDBFD"/>
    <path d="M109.454 20.3377C112.983 20.3377 115.844 17.477 115.844 13.9481C115.844 10.4193 112.983 7.55859 109.454 7.55859C105.925 7.55859 103.064 10.4193 103.064 13.9481C103.064 17.477 105.925 20.3377 109.454 20.3377Z" fill="#FFE370"/>
    <path d="M109.454 104.349C112.983 104.349 115.844 101.489 115.844 97.9598C115.844 94.431 112.983 91.5703 109.454 91.5703C105.925 91.5703 103.064 94.431 103.064 97.9598C103.064 101.489 105.925 104.349 109.454 104.349Z" fill="#FFE370"/>
    <path d="M98.9062 13.9473C98.9062 8.13172 103.638 3.40039 109.453 3.40039C115.269 3.40039 120 8.13172 120 13.9473C120 19.7628 115.269 24.4941 109.453 24.4941C103.638 24.4941 98.9062 19.763 98.9062 13.9473ZM105.938 13.9473C105.938 15.8858 107.515 17.4629 109.453 17.4629C111.392 17.4629 112.969 15.8858 112.969 13.9473C112.969 12.0087 111.392 10.4316 109.453 10.4316C107.515 10.4316 105.938 12.0087 105.938 13.9473Z" fill="#FFB54C"/>
    <path d="M98.9062 97.959C98.9062 92.1434 103.638 87.4121 109.453 87.4121C115.269 87.4121 120 92.1434 120 97.959C120 103.775 115.269 108.506 109.453 108.506C103.638 108.506 98.9062 103.775 98.9062 97.959ZM105.938 97.959C105.938 99.8975 107.515 101.475 109.453 101.475C111.392 101.475 112.969 99.8975 112.969 97.959C112.969 96.0205 111.392 94.4434 109.453 94.4434C107.515 94.4434 105.938 96.0202 105.938 97.959Z" fill="#FFB54C"/>
    <path d="M42.6877 101.475H17.5781C15.6366 101.475 14.0625 99.9005 14.0625 97.959C14.0625 96.0174 15.6366 94.4434 17.5781 94.4434H42.6877C44.6292 94.4434 46.2033 96.0174 46.2033 97.959C46.2033 99.9005 44.6292 101.475 42.6877 101.475Z" fill="#E8F1FD"/>
    <path d="M36.078 32.0349C34.1365 32.0349 32.5624 30.4609 32.5624 28.5193V17.4629H17.5781C15.6366 17.4629 14.0625 15.8888 14.0625 13.9473C14.0625 12.0057 15.6366 10.4316 17.5781 10.4316H36.078C38.0196 10.4316 39.5937 12.0057 39.5937 13.9473V28.5193C39.5937 30.4609 38.0196 32.0349 36.078 32.0349Z" fill="#E8F1FD"/>
    <path d="M21.1266 59.2598H3.51562C1.57406 59.2598 0 57.6857 0 55.7441C0 53.8026 1.57406 52.2285 3.51562 52.2285H21.1266C23.0681 52.2285 24.6422 53.8026 24.6422 55.7441C24.6422 57.6857 23.0681 59.2598 21.1266 59.2598Z" fill="#E8F1FD"/>
    <path d="M26.3014 75.9121H11.5098C9.5682 75.9121 7.99414 74.338 7.99414 72.3965C7.99414 70.4549 9.5682 68.8809 11.5098 68.8809H26.3014C28.243 68.8809 29.817 70.4549 29.817 72.3965C29.817 74.338 28.243 75.9121 26.3014 75.9121Z" fill="#E8F1FD"/>
    <path d="M10.5477 20.3377C14.0766 20.3377 16.9373 17.477 16.9373 13.9481C16.9373 10.4193 14.0766 7.55859 10.5477 7.55859C7.01889 7.55859 4.1582 10.4193 4.1582 13.9481C4.1582 17.477 7.01889 20.3377 10.5477 20.3377Z" fill="#FFEFB2"/>
    <path d="M10.5477 104.349C14.0766 104.349 16.9373 101.489 16.9373 97.9598C16.9373 94.431 14.0766 91.5703 10.5477 91.5703C7.01889 91.5703 4.1582 94.431 4.1582 97.9598C4.1582 101.489 7.01889 104.349 10.5477 104.349Z" fill="#FFEFB2"/>
    <path d="M10.5469 24.4941C4.73133 24.4941 0 19.7628 0 13.9473C0 8.13172 4.73133 3.40039 10.5469 3.40039C16.3624 3.40039 21.0938 8.13172 21.0938 13.9473C21.0938 19.7628 16.3624 24.4941 10.5469 24.4941ZM10.5469 10.4316C8.60836 10.4316 7.03125 12.0087 7.03125 13.9473C7.03125 15.8858 8.60836 17.4629 10.5469 17.4629C12.4854 17.4629 14.0625 15.8858 14.0625 13.9473C14.0625 12.0087 12.4854 10.4316 10.5469 10.4316Z" fill="#FFE370"/>
    <path d="M10.5469 108.506C4.73133 108.506 0 103.775 0 97.959C0 92.1434 4.73133 87.4121 10.5469 87.4121C16.3624 87.4121 21.0938 92.1434 21.0938 97.959C21.0938 103.775 16.3624 108.506 10.5469 108.506ZM10.5469 94.4434C8.60836 94.4434 7.03125 96.0205 7.03125 97.959C7.03125 99.8975 8.60836 101.475 10.5469 101.475C12.4854 101.475 14.0625 99.8975 14.0625 97.959C14.0625 96.0205 12.4854 94.4434 10.5469 94.4434Z" fill="#FFE370"/>
    <path d="M97.5937 60.5246C93.4687 75.2504 83.5968 96.1965 60 113.489C41.1047 99.6676 28.4976 81.9746 22.5 60.8527C19.05 48.7004 18.8648 38.709 19.0101 34.2746C43.2164 26.5613 55.0101 18.323 60 13.9473C64.9898 18.323 76.7836 26.5637 100.995 34.2746C101.145 38.6527 100.973 48.4543 97.5937 60.5246Z" fill="#94E671"/>
    <path d="M97.5938 60.5246C93.4688 75.2504 83.5969 96.1965 60 113.489V13.9473C64.9898 18.323 76.7836 26.5637 100.995 34.2746C101.145 38.6527 100.973 48.4543 97.5938 60.5246Z" fill="#52CC7B"/>
    <path d="M62.1742 111.388L60.0001 108.764C42.9001 96.2559 31.4884 80.241 26.0603 61.1254C22.9384 50.1262 22.772 41.084 22.9032 37.0715C44.8103 30.0895 55.4837 22.634 60.0001 18.673L62.1742 16.5119V13.4148L60.0001 10.1348C58.8751 10.1348 57.865 10.6738 57.204 11.5832C56.836 12.0285 48.34 21.952 18.5157 31.0762C17.1798 31.484 16.2118 32.6465 16.0524 34.0363C15.9939 34.5402 14.6837 46.5777 19.2048 62.7238C21.8532 72.1785 25.9665 81.1082 31.4298 89.2668C38.2431 99.4434 47.1775 108.427 57.9892 115.967C58.5939 116.389 59.297 116.6 60.0001 116.6L62.1742 113.771V111.388Z" fill="#52CC7B"/>
    <path d="M62.0293 36.633L60.0008 33.748C51.7016 33.748 44.9492 40.5004 44.9492 48.7996V54.2441C44.9492 56.1848 46.5242 57.7598 48.4648 57.7598C50.4078 57.7598 51.9805 56.1848 51.9805 54.2441V48.7996C51.9805 44.377 55.5781 40.7793 60.0008 40.7793L62.0293 38.5328V36.633Z" fill="#404A80"/>
    <path d="M100.795 62.7238C98.1469 72.1785 94.0336 81.1082 88.5703 89.2668C81.757 99.4434 72.8227 108.427 62.0109 115.967C61.4062 116.389 60.7031 116.6 60 116.6V108.764C81.3563 93.1129 90.2906 74.1566 94.0242 60.8277C97.0828 49.9035 97.2375 41.0324 97.1016 37.0715C75.1898 30.0918 64.5164 22.634 60 18.673V10.1348C61.125 10.1348 62.1352 10.6738 62.7961 11.5832C63.1641 12.0285 71.6602 21.952 101.484 31.0762C102.82 31.484 103.788 32.6465 103.948 34.0363C104.006 34.5402 105.316 46.5777 100.795 62.7238Z" fill="#3D997A"/>
    <path d="M75.0516 48.7996V54.2441C75.0516 56.1848 73.4766 57.7598 71.5359 57.7598C69.593 57.7598 68.0203 56.1848 68.0203 54.2441V48.7996C68.0203 44.377 64.4227 40.7793 60 40.7793V33.748C68.2992 33.748 75.0516 40.5004 75.0516 48.7996Z" fill="#283366"/>
    <path d="M63.1454 57.7588L59.9996 50.7285H47.0621C43.5277 50.7285 40.6543 53.602 40.6543 57.1363V77.2691C40.6543 80.8035 43.5277 83.677 47.0621 83.677H59.9996L63.1454 75.2863V57.7588Z" fill="#FFE370"/>
    <path d="M79.3453 57.1363V77.2691C79.3453 80.8035 76.4719 83.677 72.9375 83.677H60V50.7285H72.9375C76.4719 50.7285 79.3453 53.602 79.3453 57.1363Z" fill="#FFB54C"/>
    <path d="M63.5156 65.4941V68.9113C63.5156 70.8543 61.9406 72.427 60 72.427C58.0594 72.427 56.4844 70.8543 56.4844 68.9113V65.4941C56.4844 63.5512 58.0594 61.9785 60 61.9785C61.9406 61.9785 63.5156 63.5509 63.5156 65.4941Z" fill="#404A80"/>
    <path d="M63.5156 65.4941V68.9113C63.5156 70.8543 61.9406 72.427 60 72.427V61.9785C61.9406 61.9785 63.5156 63.5509 63.5156 65.4941Z" fill="#283366"/>
  </svg>`,
    title: "Data Encryption",
    description: `Our platform employs industry-standard encryption protocols to safeguard your data at every stage of its journey, whether it's being transmitted over the internet or stored on our servers.
    <br><br> This means that even if data were to be intercepted, it would be unreadable and unusable to anyone without the proper decryption keys.`,
  },
  {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
    <g clip-path="url(#clip0_1_14675)">
      <path d="M90 35.625H99.8438C105.659 35.625 110.391 30.8937 110.391 25.0781V21.4526C114.745 19.9816 117.891 15.8604 117.891 11.0156C117.891 4.94156 112.949 0 106.875 0C100.801 0 95.8594 4.94156 95.8594 11.0156C95.8594 15.8604 99.0047 19.9816 103.359 21.4526V25.0781C103.359 27.0166 101.782 28.5938 99.8438 28.5938H90C88.0584 28.5938 86.4844 30.1678 86.4844 32.1094C86.4844 34.0509 88.0584 35.625 90 35.625ZM106.875 7.03125C109.072 7.03125 110.859 8.81859 110.859 11.0156C110.859 13.2127 109.072 15 106.875 15C104.678 15 102.891 13.2127 102.891 11.0156C102.891 8.81859 104.678 7.03125 106.875 7.03125Z" fill="#47568C"/>
      <path d="M108.984 39.6094C104.14 39.6094 100.018 42.7547 98.5474 47.1094H89.7656C87.8241 47.1094 86.25 48.6834 86.25 50.625C86.25 52.5666 87.8241 54.1406 89.7656 54.1406H98.5474C100.018 58.4953 104.14 61.6406 108.984 61.6406C115.058 61.6406 120 56.6991 120 50.625C120 44.5509 115.058 39.6094 108.984 39.6094ZM108.984 54.6094C106.787 54.6094 105 52.822 105 50.625C105 48.428 106.787 46.6406 108.984 46.6406C111.181 46.6406 112.969 48.428 112.969 50.625C112.969 52.822 111.181 54.6094 108.984 54.6094Z" fill="#47568C"/>
      <path d="M92.1469 19.9125L71.5219 0.928125C70.8727 0.332813 70.0219 0 69.1406 0H3.51562C1.575 0 0 1.575 0 3.51562V116.484C0 118.425 1.575 120 3.51562 120H89.7656C91.7063 120 93.2812 118.425 93.2812 116.484V22.5C93.2812 21.5367 92.8852 20.5922 92.1469 19.9125Z" fill="#F2F2FC"/>
      <path d="M93.2812 22.5V116.484C93.2812 118.425 91.7062 120 89.7656 120H46.6406V0H69.1406C70.0219 0 70.8727 0.332813 71.5219 0.928125L92.1469 19.9125C92.8852 20.5922 93.2812 21.5367 93.2812 22.5Z" fill="#D6D6F2"/>
      <path d="M92.1469 19.9125L71.5219 0.928125C70.8727 0.332813 70.0219 0 69.1406 0V18.9844C69.1406 20.925 70.7156 22.5 72.6562 22.5H93.2812C93.2812 21.5367 92.8852 20.5922 92.1469 19.9125Z" fill="#BFBFEF"/>
      <path d="M53.907 86.0157C51.9654 86.0157 50.3913 84.4416 50.3913 82.5001V71.0089C49.0186 71.097 47.6653 70.3782 47.0126 69.0723C46.1442 67.3358 46.8481 65.2238 48.5848 64.3557L52.3348 62.4807C53.4249 61.9355 54.7188 61.9939 55.7552 62.6344C56.7919 63.275 57.4226 64.4068 57.4226 65.6251V82.5001C57.4226 84.4416 55.8485 86.0157 53.907 86.0157Z" fill="#6BA7FF"/>
      <path d="M70.3132 86.0157C68.3716 86.0157 66.7976 84.4416 66.7976 82.5001V71.0089C65.427 71.097 64.0716 70.3782 63.4188 69.0723C62.5505 67.3358 63.2543 65.2238 64.991 64.3557L68.741 62.4807C69.8311 61.9355 71.1248 61.9939 72.1615 62.6344C73.1981 63.275 73.8288 64.4068 73.8288 65.6251V82.5001C73.8288 84.4416 72.2548 86.0157 70.3132 86.0157Z" fill="#6BA7FF"/>
      <path d="M30 86.0156C24.443 86.0156 19.9219 81.4945 19.9219 75.9375V72.1875C19.9219 66.6305 24.443 62.1094 30 62.1094C35.557 62.1094 40.0781 66.6305 40.0781 72.1875V75.9375C40.0781 81.4945 35.557 86.0156 30 86.0156ZM30 69.1406C28.32 69.1406 26.9531 70.5075 26.9531 72.1875V75.9375C26.9531 77.6175 28.32 78.9844 30 78.9844C31.68 78.9844 33.0469 77.6175 33.0469 75.9375V72.1875C33.0469 70.5075 31.68 69.1406 30 69.1406Z" fill="#00E4EF"/>
      <path d="M70.3132 56.0157C68.3716 56.0157 66.7976 54.4416 66.7976 52.5001V41.0089C65.427 41.0986 64.0716 40.3782 63.4188 39.0722C62.5505 37.3358 63.2543 35.2238 64.991 34.3557L68.741 32.4807C69.8311 31.9358 71.1248 31.9936 72.1615 32.6344C73.1981 33.275 73.8288 34.4068 73.8288 35.6251V52.5001C73.8288 54.4416 72.2548 56.0157 70.3132 56.0157Z" fill="#6BA7FF"/>
      <path d="M46.875 32.1094C46.7977 32.1094 46.718 32.1094 46.6406 32.1117C41.1914 32.2359 36.7969 36.7078 36.7969 42.1875V45.9375C36.7969 51.4172 41.1914 55.8891 46.6406 56.0133C46.718 56.0156 46.7977 56.0156 46.875 56.0156C52.432 56.0156 56.9531 51.4945 56.9531 45.9375V42.1875C56.9531 36.6305 52.432 32.1094 46.875 32.1094ZM49.9219 45.9375C49.9219 47.618 48.5555 48.9844 46.875 48.9844C46.7953 48.9844 46.718 48.982 46.6406 48.975C45.0703 48.8555 43.8281 47.5383 43.8281 45.9375V42.1875C43.8281 40.5867 45.0703 39.2695 46.6406 39.1523C46.718 39.143 46.7953 39.1406 46.875 39.1406C48.5555 39.1406 49.9219 40.507 49.9219 42.1875V45.9375Z" fill="#00E4EF"/>
      <path d="M56.9531 42.1875V45.9375C56.9531 51.4945 52.432 56.0156 46.875 56.0156C46.7977 56.0156 46.718 56.0156 46.6406 56.0133V48.975C46.718 48.982 46.7953 48.9844 46.875 48.9844C48.5555 48.9844 49.9219 47.618 49.9219 45.9375V42.1875C49.9219 40.507 48.5555 39.1406 46.875 39.1406C46.7953 39.1406 46.718 39.143 46.6406 39.1523V32.1117C46.718 32.1094 46.7977 32.1094 46.875 32.1094C52.432 32.1094 56.9531 36.6305 56.9531 42.1875Z" fill="#6BA7FF"/>
      <path d="M27.1882 56.0157C25.2467 56.0157 23.6726 54.4416 23.6726 52.5001V41.0089C22.2994 41.0986 20.9463 40.3782 20.2938 39.0722C19.4255 37.3358 20.1293 35.2238 21.866 34.3557L25.616 32.4807C26.7061 31.9358 27.9999 31.9936 29.0365 32.6344C30.0731 33.275 30.7038 34.4068 30.7038 35.6251V52.5001C30.7038 54.4416 29.1298 56.0157 27.1882 56.0157Z" fill="#00E4EF"/>
      <path d="M38.4375 25.7812H23.4375C21.4959 25.7812 19.9219 24.2072 19.9219 22.2656C19.9219 20.3241 21.4959 18.75 23.4375 18.75H38.4375C40.3791 18.75 41.9531 20.3241 41.9531 22.2656C41.9531 24.2072 40.3791 25.7812 38.4375 25.7812Z" fill="#61729B"/>
      <path d="M119.999 88.125C119.999 88.2352 119.999 88.3453 119.997 88.4555C119.821 98.7164 111.512 106.875 101.249 106.875C93.5616 106.875 86.9545 102.246 84.0623 95.625H80.0498C79.2108 95.625 78.3998 95.925 77.7623 96.4711L74.4741 99.2883C73.1569 100.418 71.2162 100.418 69.8991 99.2883L68.0967 97.7438C66.7022 96.5484 64.6209 96.6281 63.3225 97.9266L62.5795 98.6695C61.1686 100.08 58.8717 100.036 57.517 98.5758L50.3873 90.8977C49.6608 90.1172 49.3608 89.1 49.4686 88.125C49.5647 87.232 50.003 86.3719 50.767 85.7602L56.2233 81.3961C56.8467 80.8969 57.6225 80.625 58.4194 80.625H84.0623C86.9545 74.0039 93.5616 69.375 101.249 69.375C111.604 69.375 119.999 77.7703 119.999 88.125Z" fill="#FFBE40"/>
      <path d="M119.999 88.125C119.999 88.2352 119.999 88.3453 119.997 88.4555C119.821 98.7164 111.512 106.875 101.249 106.875C93.5616 106.875 86.9545 102.246 84.0623 95.625H80.0498C79.2108 95.625 78.3998 95.925 77.7623 96.4711L74.4741 99.2883C73.1569 100.418 71.2162 100.418 69.8991 99.2883L68.0967 97.7438C66.7022 96.5484 64.6209 96.6281 63.3225 97.9266L62.5795 98.6695C61.1686 100.08 58.8717 100.036 57.517 98.5758L50.3873 90.8977C49.6608 90.1172 49.3608 89.1 49.4686 88.125H119.999Z" fill="#FF9F40"/>
      <path d="M106.641 84.6094C104.698 84.6094 103.123 86.1844 103.123 88.125C103.123 90.0656 104.698 91.6406 106.641 91.6406C108.582 91.6406 110.157 90.0656 110.157 88.125C110.157 86.1844 108.582 84.6094 106.641 84.6094Z" fill="#FF9F40"/>
      <path d="M110.157 88.125C110.157 90.0656 108.582 91.6406 106.641 91.6406C104.698 91.6406 103.123 90.0656 103.123 88.125H110.157Z" fill="#EF8729"/>
    </g>
    <defs>
      <clipPath id="clip0_1_14675">
        <rect width="120" height="120" fill="white"/>
      </clipPath>
    </defs>
  </svg>`,
    title: "Data access",
    description: `We take access control seriously, employing stringent measures to ensure that only authorized individuals have access to your data.
    <br><br> Our platform utilizes advanced user authentication techniques, such as multi-factor authentication, along with granular role-based access controls.
    <br><br> Regular audits are conducted to review and update user permissions, further strengthening data security`,
  },
];

export const OgSizeList = [
  { id: 1, value: "0-50" },
  { id: 2, value: "50-200" },
  { id: 3, value: "500-5000" },
  { id: 4, value: ">5000" },
  { id: 5, value: "Individual Contributor" },
];

export const JobFunctionList = [
  { id: 1, value: "Product" },
  { id: 2, value: "Technology" },
  { id: 3, value: "Finance" },
  { id: 4, value: "Sales and Marketing" },
  { id: 5, value: "Others" },
];

export const JobPosition = [
  { id: 1, value: "Senior management" },
  { id: 2, value: "Manager" },
  { id: 3, value: "Individual Contributor" },
];
