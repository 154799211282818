import React from 'react'
import "./styles.scss"

export default function OurMission() {
    return (
        <div id="features" className='outmission'>

            <h1>FEATURES</h1> 

            <div>Reimagine Your Workday</div>
            <div>with <span className='animated-word'>ekai</span></div>
        </div>
    )
}
